import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/root/personal-web-page/packages/gatsby-theme-googlesheets-personal-web/src/components/MdxLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Courses I'm teaching`}</h1>
    <h2>{`Winter semester`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://hci.mario-cagalj.from.hr"
        }}>{`Human Computer Interaction`}</a></li>
    </ul>
    <h2>{`Summer semester`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cns.mario-cagalj.from.hr"
        }}>{`Cryptography and Network Security`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      